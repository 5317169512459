import Facebook from "files/fb";
import Instagram from "files/insta";
import LinkedIn from "files/linkedin";
import AppLogo from "files/logo";
import Mail from "files/mail";
import Twitter from "files/twitter";
import Whatsapp from "files/whatsapp";
import React from "react";

const Footer = () => {
  return (
    
    <footer className="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">Footer</h2>
      <div className="mx-auto max-w-7xl px-6 pb-18 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-6">
            <AppLogo width={150} height={40} />
            <p className="text-sm text-gray-600 ">Collaboration Unified, Workflow Simplified</p>
           

            <div className="flex space-x-6">
              <a href="mailto:kamal@qubernet.com" target="_blank" rel="noreferrer" className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">Gmail</span>
                <Mail width={22} height={22} />
              </a>
              <a href="https://www.linkedin.com/company/kwapio/" className="text-yellow-500 hover:text-gray-500" target="_blank" rel="noreferrer">
                <span className="sr-only">LinkedIn</span>
                <LinkedIn width={20} height={20} />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61558690114763&mibextid=LQQJ4d" className="text-gray-400 hover:text-blue-400" target="_blank" rel="noreferrer">
                <span className="sr-only">Facebook</span>
                <Facebook width={20} height={20} />
              </a>
              <a href="https://x.com/kwapio?s=11" className="text-gray-400 hover:text-blue-400" target="_blank" rel="noreferrer">
                <span className="sr-only">Twitter</span>
                <Twitter width={25} height={25} />
              </a>
              <a href="https://www.instagram.com/kwapiotech?igsh=MTN6bjgwYnhuMWF6eQ%3D%3D&utm_source=qr" className="text-gray-400 hover:text-blue-400" target="_blank" rel="noreferrer">
                <span className="sr-only">Instagram</span>
                <Instagram width={22} height={22} />
              </a>
              <a href="https://whatsapp.com/channel/0029Vaklq2nEAKWHvL0P230j" className="text-gray-400 hover:text-blue-400" target="_blank" rel="noreferrer">
                <span className="sr-only">WhatsApp</span>
                <Whatsapp width={22} height={22} />
              </a>
            </div>

            <div className="space-y-2">
              <p className="text-sm text-gray-600 m-0">Address :</p>
              <p className="text-sm text-gray-600 m-0">
    No-90 Flat B-104, Swastika Apartment, Second Floor, Gomathi Nagar, Velachery, Chennai, Tamil Nadu 600042.
              </p>
            </div>
          </div>
       

          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Work</h3>
                <ul role="list" className="mt-6 space-y-4">
                  <li>
                    <a href="#" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Home</a>
                  </li>
                  <li>
                    <a href="#" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Features</a>
                  </li>
                  <li>
                    <a href="#" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Customer</a>
                  </li>
                  <li>
                    <a href="#about" className="text-sm leading-6 text-gray-600 hover:text-gray-900">About us</a>
                  </li>
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Company</h3>
                <ul role="list" className="mt-6 space-y-4">
                  <li>
                    <a href="#" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Management</a>
                  </li>
                  <li>
                    <a href="#" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Our story</a>
                  </li>
                  <li>
                    <a href="/career" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Career</a>
                  </li>
                  <li>
                    <a href="#" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Partnership</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Support</h3>
                <ul role="list" className="mt-6 space-y-4">
                  <li>
                    <a href="mailto:kamal@qubernet.com" target="_blank"
                      rel="noreferrer" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Support center</a>
                  </li>
                  <li>
                    <a href="mailto:kamal@qubernet.com" target="_blank"
                      rel="noreferrer" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Transcation</a>
                  </li>
                  <li>
                    <a href="#" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Privacy policy</a>
                  </li>
                  <li>
                    <a href="mailto:kamal@qubernet.com" target="_blank"
                      rel="noreferrer" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Contact us</a>
                  </li>
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Blogs</h3>
                <ul role="list" className="mt-6 space-y-4">
                  <li>
                    <a href="#" className="text-sm leading-6 text-gray-600 hover:text-gray-900">SEO & SEM</a>
                  </li>
                  <li>
                    <a href="#" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Ad Campaigns</a>
                  </li>
                  <li>
                    <a href="#" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Virtual Reality</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-gray-900/10 pt-4 sm:mt-20 lg:mt-24 text-center">
          <p className="text-xs leading-5 text-gray-500">&copy;2024. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};


export default Footer;